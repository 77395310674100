import { ConfigurableView, SortDirection, SortField, ViewTypeName } from '@air/api/types';

export enum ConfigVisibilityToggleType {
  dateCreated = 'dateCreated',
  dateModified = 'dateModified',
  dateTaken = 'dateTaken',
  resolution = 'resolution',
  ext = 'ext',
  size = 'size',
  titleAndMetadata = 'titleAndMetadata',
  type = 'type',
  tagCount = 'tagCount',
  boardCount = 'boardCount',
  openCommentCount = 'openCommentCount',
}

export const getVisibilityToggleTypeFromString = (id: string): ConfigVisibilityToggleType | null => {
  switch (id) {
    case 'dateTaken':
      return ConfigVisibilityToggleType.dateTaken;
    case 'dateCreated':
      return ConfigVisibilityToggleType.dateCreated;
    case 'dateModified':
      return ConfigVisibilityToggleType.dateModified;
    case 'ext':
      return ConfigVisibilityToggleType.ext;
    case 'size':
      return ConfigVisibilityToggleType.size;
    case 'titleAndMetadata':
      return ConfigVisibilityToggleType.titleAndMetadata;
    case 'type':
      return ConfigVisibilityToggleType.type;
    case 'tagCount':
      return ConfigVisibilityToggleType.tagCount;
    case 'boardCount':
      return ConfigVisibilityToggleType.boardCount;
    case 'openCommentCount':
      return ConfigVisibilityToggleType.openCommentCount;
    case 'resolution':
      return ConfigVisibilityToggleType.resolution;
    default:
      return null;
  }
};

const dateModifiedDefaultSort = {
  direction: SortDirection.desc,
  name: 'dateModified',
};

export interface DefaultBoardlessConfigViewsParams {
  pageId: string;
  defaultSort?: SortField;
}

export const defaultGalleryViewId = (pageId: string) => `${pageId}-gallery`;
export const defaultTableViewId = (pageId: string) => `${pageId}-table`;

export const getDefaultBoardlessConfigViews = ({
  defaultSort = dateModifiedDefaultSort,
  pageId,
}: DefaultBoardlessConfigViewsParams): ConfigurableView[] => {
  return [
    {
      id: defaultGalleryViewId(pageId),
      isDefault: true,
      sortFields: [defaultSort],
      viewType: {
        name: ViewTypeName.gallery,
      },
      visibleFields: [],
      customFieldsVisible: false,
      metadataFieldsVisible: false,
    },
    {
      id: defaultTableViewId(pageId),
      isDefault: false,
      sortFields: [defaultSort],
      viewType: {
        name: ViewTypeName.table,
      },
      visibleFields: [
        {
          name: 'dateCreated',
        },
        {
          name: 'dateModified',
        },
        {
          name: 'ext',
        },
        {
          name: 'size',
        },
        {
          name: 'type',
        },
        {
          name: 'resolution',
        },
        {
          name: 'tagCount',
        },
        {
          name: 'boardCount',
        },
      ],
      customFieldsVisible: false,
      metadataFieldsVisible: false,
    },
  ];
};
