import { Check } from '@air/next-icons';
import { ReactNode } from 'react';

import { baseFiltersOptions, FilterType } from '~/components/FiltersMenu/types';

export const getFilterProps = ({
  type,
  onSelect,
  isSelected,
}: {
  type: FilterType;
  onSelect: (type: FilterType) => void;
  isSelected?: boolean;
}): {
  disabled?: boolean;
  id: string;
  label: string;
  onSelect: () => void;
  prefix?: ReactNode;
  suffix?: ReactNode;
} => {
  const Prefix = baseFiltersOptions[type].icon;

  return {
    disabled: isSelected,
    id: baseFiltersOptions[type].id,
    label: baseFiltersOptions[type].label,
    onSelect: () => onSelect(baseFiltersOptions[type].id),
    prefix: Prefix ? <Prefix className="size-4 text-grey-10" /> : null,
    suffix: isSelected ? <Check className="size-4 text-grey-10" /> : null,
  };
};
