import { TriangleDown } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { Checkbox } from '@air/primitive-checkbox';
import {
  SelectDropdownMenu,
  type SelectDropdownMenuOption,
  type SelectDropdownMenuSelectedOption,
} from '@air/primitive-dropdown-menu';
import { memo, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { centralizedClipPDFNumberOfPagesSelector } from '~/store/centralizedClip/selectors';

export type SharePrivateClipPDFPageProps = {
  onSelectPage: (page?: number) => void;
};

export const SharePrivateClipPDFPage = memo(({ onSelectPage }: SharePrivateClipPDFPageProps) => {
  const [selectedPage, setSelectedPage] = useState<SelectDropdownMenuSelectedOption>({
    id: '1',
    label: '1',
    type: 'item',
  });
  const [showPage, setShowPage] = useState(false);
  const pdfNumberOfPages = useSelector(centralizedClipPDFNumberOfPagesSelector);

  const options = useMemo<SelectDropdownMenuOption[] | undefined>(() => {
    if (!pdfNumberOfPages) return;

    return Array.from({ length: pdfNumberOfPages }, (_, index) => {
      const page = (index + 1).toString();

      return {
        canSelect: true,
        id: page,
        label: page,
        type: 'item',
      };
    });
  }, [pdfNumberOfPages]);

  const onSelect = useCallback(
    (option: SelectDropdownMenuSelectedOption) => {
      setSelectedPage(option);

      onSelectPage(parseInt(option.id));
    },
    [onSelectPage],
  );

  const onToggle = useCallback(
    (value: boolean | 'indeterminate') => {
      setShowPage(!!value);

      if (!value) {
        onSelectPage(undefined);
      } else {
        onSelectPage(parseInt(selectedPage.id));
      }
    },
    [onSelectPage, selectedPage],
  );

  return (
    <div className="flex h-6 items-center gap-2 text-14 text-grey-12">
      <Checkbox
        id="share-private-clip-pdf"
        onCheckedChange={onToggle}
        data-testid="SHARE_PRIVATE_CLIP_PDF_PAGE_CHECKBOX"
      />
      <label htmlFor="share-private-clip-pdf">Start at specific page{showPage && ':'}</label>
      {showPage && (
        <SelectDropdownMenu
          data-testid="SHARE_PRIVATE_CLIP_PDF_PAGE_SELECT"
          selectedOption={selectedPage}
          options={options}
          trigger={(selectedOption) => (
            <Button
              data-testid="SHARE_PRIVATE_CLIP_PAGE_SELECT_TRIGGER"
              appearance="ghost"
              color="grey"
              suffix={<TriangleDown className="size-4" />}
              size="medium"
            >
              {selectedOption?.id ?? 'Select page'}
            </Button>
          )}
          onSelect={onSelect}
          size="small"
        />
      )}
    </div>
  );
});

SharePrivateClipPDFPage.displayName = 'SharePrivateClipPDFPage';
