import { ArrowDown as ArrowDownIcon } from '@air/next-icons';
import VisuallyHidden from '@reach/visually-hidden';
import { SortDirection } from '@tanstack/react-table';
import classNames from 'classnames';
import { forwardRef, HTMLAttributes, memo } from 'react';

import { SORT_BUTTON, SORT_BUTTON_ARROW, SORT_BUTTON_TYPE } from '~/constants/testIDs';

const BUTTON_CLASS_NAMES =
  'flex h-8 cursor-pointer items-center justify-start border-0 bg-transparent py-0 pr-2 pl-3 text-12 font-medium transition-all hover:bg-grey-4 active:bg-grey-5 group-hover:bg-grey-3';

export type SortButtonProps = Pick<HTMLAttributes<HTMLDivElement>, 'children' | 'className' | 'style'> & {
  direction: SortDirection;
  isActive: boolean;
  onArrowClick?: () => void;
  onClick?: () => void;
  type?: string;
};

export const _SortButton = forwardRef<HTMLDivElement, SortButtonProps>(
  ({ children, className, direction, onArrowClick, onClick, style, type, isActive }: SortButtonProps, forwardedRef) => {
    return (
      <div
        data-testid={SORT_BUTTON}
        data-direction={direction}
        className={classNames('group inline-flex items-stretch gap-px', className)}
        ref={forwardedRef}
        style={style}
      >
        <button
          className={classNames(
            BUTTON_CLASS_NAMES,
            'rounded-l',
            'group-focus-within:bg-grey-3',
            direction ? 'text-grey-11' : 'text-grey-10',
          )}
          data-testid={`${SORT_BUTTON_TYPE}-${type}`}
          data-direction={direction}
          onClick={onClick}
        >
          {children}
        </button>
        <button
          className={classNames(
            BUTTON_CLASS_NAMES,
            'w-6 justify-center rounded-r !p-0 opacity-0',
            'group-focus-within:opacity-100 group-hover:opacity-100',
            isActive ? 'opacity-100' : 'opacity-0',
          )}
          data-testid={`${SORT_BUTTON_ARROW}-${type}-${direction}`}
          data-direction={direction}
          onClick={onArrowClick || onClick}
        >
          <ArrowDownIcon
            className={classNames('block h-3 w-3 transition-transform', {
              'rotate-180': direction === 'asc',
              'rotate-0': direction === 'desc',
            })}
          />
          <VisuallyHidden>{direction === 'desc' ? 'Sort descending' : 'Sort ascending'}</VisuallyHidden>
        </button>
      </div>
    );
  },
);

_SortButton.displayName = 'SortButton';

export const SortButton = memo(_SortButton);
