import { AirActionTrackingLocation } from '@air/analytics';
import { Board, Clip } from '@air/api/types';
import { Close } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { IconButton } from '@air/primitive-icon-button';
import { Modal, ModalCloseButton, ModalTitle } from '@air/primitive-modal';
import { useToasts } from '@air/provider-toast';
import pluralize from 'pluralize';
import React, { useCallback, useState } from 'react';

import {
  BoardSearchOption,
  PrivateBoardSearch,
  PrivateBoardSearchProps,
} from '~/components/BoardSearch/PrivateBoardSearch';
import BoardThumbnail from '~/components/BoardThumbnail/BoardThumbnail';
import { ListSectionHeader } from '~/components/Shared/ListSectionHeader';
import { ToastLink } from '~/components/UI/ToastLink';
import { ADD_CLIPS_TO_BOARD_MODAL } from '~/constants/testIDs';
import { useBoardPermissionsCache } from '~/hooks/useBoardPermissionsCache';
import { useGoToBoardPage } from '~/hooks/useGoToBoardPage';
import { useShowSubscriptionExpiredModal } from '~/hooks/useShowSubscriptionExpiredModal';
import { useAddAssetsToBoards } from '~/swr-hooks/boards/useAddAssetsToBoards';
import { canAddAssetsToBoard } from '~/utils/permissions/boardPermissions';

export interface AddClipsToBoardModalProps {
  clipIds: Clip['id'][];
  trackLocation: AirActionTrackingLocation;
  onSubmit?: () => void;
  parentBoardId?: Board['id'];
}

const getInstructions = (isPlural: boolean) =>
  isPlural
    ? 'The assets will appear on a new board (or boards) and will not be removed from their current locations.'
    : 'The asset will appear on a new board (or boards) and will not be removed from its current location.';

export const AddAssetsToBoardsModal = ({
  clipIds,
  onClose,
  trackLocation,
  onSubmit,
  parentBoardId,
}: AirModalProps<AddClipsToBoardModalProps>) => {
  const { goToBoardPage } = useGoToBoardPage();
  const { showToast } = useToasts();
  const [adding, setAdding] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [selectedBoards, setSelectedBoards] = useState<BoardSearchOption[]>([]);
  const isBoardSelected = useCallback(
    (board: Pick<Board, 'id'>) => {
      return selectedBoards.some((b) => b.id === board.id);
    },
    [selectedBoards],
  );
  const { getBoardPermissions } = useBoardPermissionsCache();

  const { showingSubscriptionExpiredModal } = useShowSubscriptionExpiredModal({ onClose });
  const addAssetsToBoardMutation = useAddAssetsToBoards();

  const onBoardSelectChange: PrivateBoardSearchProps['onBoardSelectChange'] = useCallback(({ board, selected }) => {
    setSelectedBoards((current) => {
      if (selected) {
        return [...current, board];
      } else {
        return current.filter((b) => b.id !== board.id);
      }
    });
  }, []);

  const getIsBoardDisabled: Required<PrivateBoardSearchProps>['getIsBoardDisabled'] = useCallback(
    (board) => {
      const boardPermissions = getBoardPermissions(board.id);

      if (!canAddAssetsToBoard(boardPermissions)) {
        return {
          isDisabled: true,
          message: 'You do not have permission to add assets to this board',
        };
      }
    },
    [getBoardPermissions],
  );

  if (showingSubscriptionExpiredModal) {
    return null;
  }

  const goToBoard = (board: Pick<Board, 'id' | 'title'>) => {
    goToBoardPage({ board, trackLocation: 'add-clips-to-board-toast' });
  };

  const handleAddClick = async () => {
    const boards = selectedBoards;

    setAdding(true);

    try {
      await addAssetsToBoardMutation.mutateAsync({
        clipIds,
        boards,
        trackLocation,
        parentBoardId,
      });

      onSubmit?.();
      onClose();
      showToast(
        <>
          {`${pluralize('item', clipIds.length, true)} added to `}
          {boards.length === 1 ? (
            <ToastLink onClick={() => goToBoard(boards[0])}>{boards[0].title}</ToastLink>
          ) : (
            <>multiple boards</>
          )}
        </>,
      );
    } catch (_error) {
      setAdding(false);
      showToast('An error occurred - please try again later');
    }
  };

  return (
    <Modal isOpen onDismiss={onClose} data-testid={ADD_CLIPS_TO_BOARD_MODAL}>
      <div className="flex flex-col gap-4">
        <header className="flex justify-between gap-4">
          <ModalTitle>Add to…</ModalTitle>
          <ModalCloseButton className="shrink-0" onClick={onClose} />
        </header>
        <p className="text-14 text-grey-10">{getInstructions(clipIds.length > 1)}</p>
        <PrivateBoardSearch
          className="mt-3 [&>.workspace-boards]:h-[240px]"
          isBoardSelected={isBoardSelected}
          onBoardSelectChange={onBoardSelectChange}
          shouldExpandLibraryInsteadOfSelect={true}
          getIsBoardDisabled={getIsBoardDisabled}
        />
        <ListSectionHeader
          className="mb-0 pb-0"
          showDowntick="always"
          onClick={() => setShowSummary(!showSummary)}
          collapsed={!showSummary}
          headerTitle={<>Selected boards ({selectedBoards.length})</>}
        />
        {showSummary && (
          <div className="flex flex-col gap-2">
            {selectedBoards.map((option, index) => (
              <div className="flex items-center justify-between rounded bg-grey-3 px-3 py-2" key={index}>
                <div className="flex items-center gap-2">
                  <BoardThumbnail board={option} sizes="32px" width={32} height={32} />
                  <div>
                    <div className="text-14 font-medium text-grey-11">{option.title}</div>
                    <div className="text-12 text-grey-10">
                      {option.ancestors?.map((a) => a.title).join(' / ') || 'Workspace Boards'}
                    </div>
                  </div>
                </div>
                <IconButton
                  appearance="ghost"
                  color="grey"
                  size="extra-small"
                  icon={Close}
                  label="Clear"
                  onClick={() => setSelectedBoards((boards) => boards.filter((b) => b.id !== option.id))}
                />
              </div>
            ))}
          </div>
        )}

        <footer className="flex justify-end gap-3">
          <Button appearance="ghost" color="grey" onClick={onClose} size="large">
            Cancel
          </Button>
          <Button
            data-testid="ADD_CLIPS_TO_BOARD_MODAL_SUBMIT"
            onClick={handleAddClick}
            isLoading={adding}
            disabled={adding || !selectedBoards.length}
            appearance="filled"
            color="blue"
            size="large"
          >
            {adding
              ? 'Adding…'
              : `Add to ${selectedBoards.length >= 1 ? pluralize('board', selectedBoards.length, true) : 'board'}`}
          </Button>
        </footer>
      </div>
    </Modal>
  );
};
