import { PublicClip } from '@air/api';
import { BaseCustomField, Board, Clip, PublicBoard } from '@air/api/types';
import { type DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { useAirModal } from '@air/provider-modal';
import { useIsLoggedIn } from '@air/utils-auth';
import Router from 'next/router';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { PublicCustomFieldAnonymousSignUpModal } from '~/components/PublicLinkAnonymousSignUpModal/PublicCustomFieldAnonymousSignUpModal';
import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { SSOCallbackData } from '~/components/PublicSSOCallback/utils';
import { customFieldIconMap } from '~/constants/customFieldIconMap';
import { useShowPrivateCustomFieldBulkEditModal } from '~/hooks/customFields/useShowPrivateCustomFieldBulkEditModal';
import {
  ShowCustomFieldBulkEditModalParams,
  useShowPublicCustomFieldBulkEditModal,
} from '~/hooks/customFields/useShowPublicCustomFieldBulkEditModal';
import { useGetAvailableCustomFields } from '~/hooks/useGetAvailableCustomFields';
import { useURLLibraryIdSelector } from '~/hooks/useURLLibraryIdSelector';
import { usePrivateCustomFieldsContext } from '~/providers/PrivateCustomFieldsProvider';
import { usePublicCustomFieldsContext } from '~/providers/PublicCustomFieldsProvider';
import { centralizedBoardLibraryIdSelector } from '~/store/centralizedBoard/selectors';
import { canChangeBoardCustomFields } from '~/utils/permissions/boardPermissions';

export type GetCustomFieldsMenuOptionsParams = {
  boards?: (Board | PublicBoard)[];
  assets?: (Clip | PublicClip)[];
};

export type GetCustomFieldsMenuOptions = (params: GetCustomFieldsMenuOptionsParams) => DropdownMenuOption[];

const useGetCustomFieldsMenuOptions = ({
  customFields,
  onFieldClick,
}: {
  customFields?: BaseCustomField[];
  onFieldClick: (params: ShowCustomFieldBulkEditModalParams) => void;
}) => {
  const getCustomFieldsMenuOptions: GetCustomFieldsMenuOptions = useCallback(
    ({ boards = [], assets = [] }) => {
      return (
        customFields?.map((field) => {
          const Icon = customFieldIconMap[field.type.title];

          return {
            id: field.id,
            label: field.name,
            onSelect: () => onFieldClick({ field, assets, boards }),
            prefix: <Icon className="size-4" />,
            type: 'item',
          };
        }) || []
      );
    },
    [customFields, onFieldClick],
  );

  return {
    getCustomFieldsMenuOptions,
  };
};

export const useGetPrivateCustomFieldsMenuOptions = () => {
  const urlLibraryId = useURLLibraryIdSelector();
  const libraryId = useSelector(centralizedBoardLibraryIdSelector);
  const { customFields } = usePrivateCustomFieldsContext();
  const { getAvailableCustomFields } = useGetAvailableCustomFields<BaseCustomField>({
    libraryId: libraryId || urlLibraryId,
  });

  const { showBulkEditModal } = useShowPrivateCustomFieldBulkEditModal();

  const availableCustomFields = useMemo(
    () => getAvailableCustomFields(customFields),
    [customFields, getAvailableCustomFields],
  );

  const { getCustomFieldsMenuOptions } = useGetCustomFieldsMenuOptions({
    customFields: availableCustomFields,
    onFieldClick: showBulkEditModal,
  });

  return {
    getCustomFieldsMenuOptions,
  };
};

export const useGetPublicCustomFieldsMenuOptions = () => {
  const { permissions } = usePublicPermissions();
  const canEditCustomFields = canChangeBoardCustomFields(permissions);
  const { customFields } = usePublicCustomFieldsContext();

  const { showBulkEditModal } = useShowPublicCustomFieldBulkEditModal();
  const [showSignUpModal] = useAirModal(PublicCustomFieldAnonymousSignUpModal);

  const { isLoggedIn } = useIsLoggedIn();

  const memoizedCustomFields = useMemo(
    () => (canEditCustomFields ? customFields : []),
    [canEditCustomFields, customFields],
  );

  const onFieldClick = useCallback(
    (params: ShowCustomFieldBulkEditModalParams) => {
      if (isLoggedIn) {
        showBulkEditModal(params);
      } else {
        Router.ready(() => {
          const ssoCallbackData: SSOCallbackData = {
            url: Router.asPath,
            action: {
              action: 'bulk-edit-custom-field-value',
              data: params,
            },
          };

          showSignUpModal({
            onLoginSuccess: () => showBulkEditModal(params),
            ssoCallbackData,
          });
        });
      }
    },
    [isLoggedIn, showBulkEditModal, showSignUpModal],
  );

  return useGetCustomFieldsMenuOptions({
    customFields: memoizedCustomFields,
    onFieldClick,
  });
};
