import { Clips, ClipsListResponse, PublicClip, ShortUrl } from '@air/api';
import { Board, Clip, ClipsListOptions, ListResponse, PublicBoard, SortDirection, SortField } from '@air/api/types';

import { PageLimits } from '~/constants/PageLimits';
import { UseGalleryViewItemsReturn } from '~/swr-hooks/gallery/types';

export const privateClipsFetcher = async ({
  params,
  sortFieldName,
  sortDirection,
  cursor,
  workspaceId,
}: {
  cursor: string | null;
  params: Partial<ClipsListOptions>;
  sortFieldName: SortField['name'];
  sortDirection: SortDirection;
  workspaceId: string;
}) => {
  const response = await Clips.search({
    cursor,
    ...params,
    limit: !cursor ? PageLimits.clips.init : PageLimits.clips.more,
    sortField: {
      direction: sortDirection,
      name: sortFieldName,
    },
    workspaceId,
  });
  return response;
};

export const publicClipsFetcher = async ({
  params,
  sortFieldName,
  shortId,
  parentId,
  sortDirection,
  cursor,
}: {
  cursor: string | null;
  shortId: string;
  parentId: string;
  params: Partial<ClipsListOptions>;
  sortFieldName: SortField['name'];
  sortDirection: SortDirection;
}) => {
  const response = await ShortUrl.searchClipsByShortId(shortId, parentId, {
    cursor,
    ...params,
    limit: !cursor ? PageLimits.clips.init : PageLimits.clips.more,
    sortField: {
      direction: sortDirection,
      name: sortFieldName,
    },
  });
  return response;
};

export const privateFilesFetcher = async ({
  params,
  sortFieldName,
  sortDirection,
  cursor,
  workspaceId,
}: {
  cursor: string | null;
  params: Partial<ClipsListOptions>;
  sortFieldName: SortField['name'];
  sortDirection: SortDirection;
  workspaceId: string;
}) => {
  const response = await Clips.searchFiles({
    ...params,
    cursor,
    limit: !cursor ? PageLimits.clips.init : PageLimits.clips.more,
    sortField: {
      direction: sortDirection,
      name: sortFieldName,
    },
    workspaceId,
  });
  return response;
};

export const publicFilesFetcher = async ({
  params,
  sortFieldName,
  sortDirection,
  parentId,
  shortId,
  cursor,
}: {
  cursor: string | null;
  shortId: string;
  parentId: string;
  params: Partial<ClipsListOptions>;
  sortFieldName: SortField['name'];
  sortDirection: SortDirection;
}) => {
  const response = await ShortUrl.searchFilesByShortId(shortId, parentId, {
    ...params,
    cursor,
    limit: !cursor ? PageLimits.clips.init : PageLimits.clips.more,
    sortField: {
      direction: sortDirection,
      name: sortFieldName,
    },
  });
  return response;
};

export const getBoardsFromBoardsData = <B extends PublicBoard | Board, C extends Clip | PublicClip>(
  data?: ListResponse<B>[],
  boardIdsToExclude: string[] = [],
): B[] =>
  data
    ? data.reduce(
        (acc, curr) => {
          if (boardIdsToExclude.length === 0) {
            acc.push(...curr.data);
          } else {
            acc.push(...curr.data.filter((board) => !boardIdsToExclude.includes(board.id)));
          }

          return acc;
        },
        [] as UseGalleryViewItemsReturn<B, C>['data']['boards']['items'],
      )
    : [];

export const getAssetFromAssetsData = <C extends Clip | PublicClip>(data?: ClipsListResponse<C>[]) =>
  data
    ? data.reduce(
        (acc, curr) => {
          const assets = curr.data.clips;
          acc.push(...assets);
          return acc;
        },
        [] as UseGalleryViewItemsReturn<undefined, C>['data']['clips']['items'],
      )
    : [];

export const galleryViewDataToUpdateMediaPlayer = <B extends PublicBoard | Board, C extends Clip | PublicClip>(
  itemsData: UseGalleryViewItemsReturn<B, C>,
) => ({
  itemsIds: [...itemsData.data.clips.items, ...itemsData.data.files.items].map(({ id, assetId }) => ({
    assetId,
    id,
  })),
  loadMore: itemsData.loadNextPage,
  hasMore: itemsData.data.boards.hasMore || itemsData.data.clips.hasMore || itemsData.data.files.hasMore,
});
