import {
  AssetCustomFieldValue,
  BoardCustomFieldValue,
  CustomFieldTypeName,
  CustomFieldValueListResponse,
} from '@air/api/types';
import { memo, useMemo } from 'react';

import { CustomField } from '~/components/CustomField';
import { SelectChipRemoveButton } from '~/components/Select/components/SelectChipRemoveButton';
import { Select, SelectProps } from '~/components/Select/Select';
import { DefaultChipType } from '~/components/Select/shared/types';
import { CUSTOM_FIELD_SELECT_COMPONENT } from '~/constants/testIDs';

export interface CustomFieldSelectProps extends Omit<SelectProps, 'options' | 'selectedOptions'> {
  field: BoardCustomFieldValue | AssetCustomFieldValue;
  cfOptions: CustomFieldValueListResponse | undefined;
}

export const CustomFieldSelect = memo(({ field, cfOptions, ...rest }: CustomFieldSelectProps) => {
  const selectedOptions = useMemo<DefaultChipType[]>(() => {
    if (field.value) {
      return [
        {
          value: field.value.id,
          label: field.value.value,
          meta: {
            color: field.value.color.name,
          },
        },
      ];
    }
    if (field.values) {
      return field.values.map((fieldValue) => ({
        value: fieldValue.id,
        label: fieldValue.value,
        meta: {
          color: fieldValue.color.name,
        },
      }));
    }
    return [];
  }, [field]);

  const options = useMemo<DefaultChipType[]>(() => {
    if (!!cfOptions) {
      return cfOptions.data.map((fieldValue) => ({
        value: fieldValue.id,
        label: fieldValue.value,
        meta: {
          color: fieldValue.color.name,
        },
      }));
    } else {
      return [];
    }
  }, [cfOptions]);

  return (
    <Select
      {...rest}
      closeMenuOnSelect={field.type.title === CustomFieldTypeName.singleSelect}
      data-testid={`${CUSTOM_FIELD_SELECT_COMPONENT}-${field.name}`}
      options={options}
      placeholder="None"
      selectedOptions={selectedOptions}
      isSearchable
      isSticky
      chipRenderer={(chip, removeSelectedItem, meta) => (
        <CustomField
          className="mb-1 mr-1"
          field={{
            id: chip.value,
            color: {
              name: chip?.meta?.color,
            },
            value: chip.label,
          }}
          suffix={meta?.isOpen ? <SelectChipRemoveButton chip={chip} onClick={removeSelectedItem} /> : undefined}
        />
      )}
      listItemRenderer={(props) => (
        <div className="rounded p-2 hover:bg-grey-4">
          <CustomField
            field={{
              id: props.value,
              color: {
                name: props?.meta?.color,
              },
              value: props.label,
            }}
          />
        </div>
      )}
      className="w-full [&_.placeholder]:text-14"
      maxDropdownHeight={300}
      isSingleSelect={field.type.title === CustomFieldTypeName.singleSelect}
      isClearable
      hideClearWhenNotFocused
      size="large"
    />
  );
});

CustomFieldSelect.displayName = 'CustomFieldSelect';
