import { SortDirection } from '@air/api/types';
import { tailwindMerge } from '@air/tailwind-variants';
import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SortButton } from '~/components/SortButton/SortButton';
import { TableCell } from '~/components/TableView/TableCell';
import { setSortingFieldAction } from '~/store/configViews/actions';
import { sortingConfigViewOptionsSelector } from '~/store/configViews/selectors';
import { getDefaultSortDirection } from '~/store/configViews/utils';

interface SortableHeaderProps {
  title: string;
  sortingType: string;
  width?: number;
  className?: string;
  style?: React.CSSProperties;
}

export const SortableHeader = memo(({ title, sortingType, width, className, ...props }: SortableHeaderProps) => {
  const dispatch = useDispatch();
  const sortingConfigViewOptions = useSelector(sortingConfigViewOptionsSelector);
  const sortingConfigViewOption = sortingConfigViewOptions.find((opt) => opt.fieldName === sortingType);
  const sortingConfigDirection = sortingConfigViewOption?.sortDirection ?? getDefaultSortDirection(sortingType);

  const onSortEnable = useCallback(() => {
    dispatch(setSortingFieldAction({ sortingFieldName: sortingType, direction: sortingConfigDirection }));
  }, [dispatch, sortingConfigDirection, sortingType]);

  const onSortChange = useCallback(() => {
    if (sortingConfigDirection === SortDirection.desc) {
      dispatch(setSortingFieldAction({ sortingFieldName: sortingType, direction: SortDirection.asc }));
    } else {
      dispatch(setSortingFieldAction({ sortingFieldName: sortingType, direction: SortDirection.desc }));
    }
  }, [dispatch, sortingConfigDirection, sortingType]);

  if (!sortingConfigViewOption) return null;

  return (
    <TableCell width={width} className={tailwindMerge('p-1.5', className)} {...props}>
      <SortButton
        isActive={sortingConfigViewOption.isCurrentSortedOption}
        direction={sortingConfigDirection}
        onClick={onSortEnable}
        onArrowClick={onSortChange}
        type={title}
      >
        {title}
      </SortButton>
    </TableCell>
  );
});

SortableHeader.displayName = 'SortableHeader';
