import { ViewTypeName } from '@air/api/types';
import { Check as CheckIcon, Grid as GridIcon, Kanban as KanbanIcon, List as ListIcon } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';

import { DropdownMenuWithActionSheet } from '~/components/Menus/DropdownMenuWithActionSheet';
import { IconType } from '~/types/icons';

export interface ViewTypeToggleMenuComponentProps {
  availableViewTypeNames: ViewTypeName[];
  currentViewTypeName: ViewTypeName;
  dataTestId?: string;
  onChange: (nextViewTypeName: ViewTypeName) => void;
}

type ViewTypeView = {
  icon: IconType;
  label: string;
};

const viewTypeViewMap: Record<ViewTypeName, ViewTypeView> = {
  [ViewTypeName.gallery]: {
    icon: GridIcon,
    label: 'Gallery',
  },
  [ViewTypeName.table]: {
    icon: ListIcon,
    label: 'Table',
  },
  [ViewTypeName.kanban]: {
    icon: KanbanIcon,
    label: 'Kanban',
  },
};

export const ViewTypeToggleMenuComponent = ({
  availableViewTypeNames,
  onChange,
  currentViewTypeName,
  dataTestId,
}: ViewTypeToggleMenuComponentProps) => {
  const { icon: currentIcon, label: currentLabel } = viewTypeViewMap[currentViewTypeName];

  return (
    <DropdownMenuWithActionSheet
      options={availableViewTypeNames.map((viewTypeName) => {
        const { icon: OptionIcon, label } = viewTypeViewMap[viewTypeName];
        return {
          id: viewTypeName,
          label: label,
          onSelect: () => onChange(viewTypeName),
          prefix: <OptionIcon className="size-4" />,
          suffix: viewTypeName === currentViewTypeName ? <CheckIcon className="size-4" /> : null,
          type: 'item',
        };
      })}
      size="small"
      trigger={
        <IconButton
          appearance="ghost"
          color="grey"
          label={currentLabel}
          icon={currentIcon}
          size="medium"
          data-testid={dataTestId}
        />
      }
      title="View Type"
    />
  );
};
