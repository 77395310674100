import { PublicClip } from '@air/api';
import { BaseCustomField, Clip } from '@air/api/types';
import { Comment as CommentIcon, Ellipsis as EllipsisHorizontalIcon, Stack as StackIcon } from '@air/next-icons';
import { memo, useMemo } from 'react';

import { CardAction } from '~/components/CardAction';
import FileExtensionPreview from '~/components/FileExtensionPreview';
import { AssetGalleryCardMetaDetails } from '~/components/Gallery/AssetGalleryCard/AssetGalleryCardMetaDetails';
import { CustomFieldsDisplay } from '~/components/Gallery/CustomFieldsDisplay';
import { FileGalleryCardMenu } from '~/components/Gallery/FileGalleryCard/FileGalleryCardMenu';
import { GalleryCard, GalleryCardProps } from '~/components/Gallery/GalleryCard/GalleryCard';
import { GalleryCardCustomFieldsAction } from '~/components/Gallery/GalleryCardCustomFieldsAction';
import { GalleryItemType } from '~/components/Gallery/types';
import { getAssetCustomFields } from '~/components/Gallery/utils';
import { FILE_CARD, FILE_CARD_SUB_MENU_BUTTON, FILE_CARD_TITLE } from '~/constants/testIDs';
import { visibleCustomFieldsViewOptionsSelector } from '~/store/configViews/selectors';
import { SelectableGalleryFileItem } from '~/store/selectedItems/types';
import { getFilename } from '~/utils/FileExtensionUtils';
import { useAirSelector } from '~/utils/ReduxUtils';

export interface FileGalleryCardProps<C>
  extends Pick<
    GalleryCardProps<SelectableGalleryFileItem>,
    'getButtonClassName' | 'getItemMenuOptions' | 'getSelectionMenuOptions' | 'isSelectable'
  > {
  cardWidth: number;
  cardHeight: number;
  file: C;
  onCustomFieldsClick: () => void;
  onDiscussionsClick?: () => void;
  onVersionsClick?: () => void;
  onFileClick: () => void;
  onFileTitleCmdClick?: () => void;
  canViewCustomFields: boolean;
  allCustomFields: BaseCustomField[] | undefined;
}

const _FileGalleryCard = <C extends Clip | PublicClip>({
  file,
  getItemMenuOptions,
  getSelectionMenuOptions,
  isSelectable = true,
  onCustomFieldsClick,
  onDiscussionsClick,
  onVersionsClick,
  onFileClick,
  onFileTitleCmdClick,
  canViewCustomFields,
  allCustomFields = [],
  cardWidth,
  getButtonClassName,
}: FileGalleryCardProps<C>) => {
  const visibleCustomFields = useAirSelector(visibleCustomFieldsViewOptionsSelector);

  const customFields = useMemo(
    () => getAssetCustomFields({ allCustomFields, clip: file, visibleCustomFields }),
    [allCustomFields, file, visibleCustomFields],
  );

  const shouldShowCustomFields = canViewCustomFields && customFields.length > 0;

  const titleLabel = getFilename(file);

  const popoverCustomFields = useMemo(
    () =>
      file?.customFields?.filter((customField) => {
        return allCustomFields.some((cf) => cf.id === customField.id);
      }),
    [file, allCustomFields],
  );

  return (
    <GalleryCard
      testId={FILE_CARD}
      title={titleLabel}
      getItemMenuOptions={getItemMenuOptions}
      getSelectionMenuOptions={getSelectionMenuOptions}
      item={{
        id: file.id,
        item: file,
        type: GalleryItemType.file,
      }}
      onClick={onFileClick}
      isSelectable={isSelectable}
      getButtonClassName={getButtonClassName}
    >
      {({ setIsMenuOpen, isHovering }) => (
        <div className="pointer-events-none relative flex size-full flex-col content-between rounded border border-grey-4 bg-grey-1 p-3">
          <div className="pointer-events-none flex items-center">
            <div className="flex flex-1 items-center gap-1">
              <div className="flex size-8 shrink-0 items-center justify-center rounded bg-pigeon-100">
                <FileExtensionPreview ext={file.ext} color="gray" />
              </div>
              {canViewCustomFields && !!popoverCustomFields?.length && (
                <GalleryCardCustomFieldsAction customFields={popoverCustomFields} onClick={onCustomFieldsClick} />
              )}
              {!!onVersionsClick && (
                <CardAction
                  data-testid="CARD_VERSIONS_INDICATOR"
                  color="light"
                  icon={<StackIcon className="size-4" />}
                  onClick={onVersionsClick}
                />
              )}
              {!!onDiscussionsClick && !!file.openCommentCount && (
                <CardAction
                  data-testid="CARD_DISCUSSIONS_INDICATOR"
                  color="light"
                  icon={<CommentIcon className="size-4" />}
                  onClick={onDiscussionsClick}
                  text={file.openCommentCount}
                />
              )}
            </div>
            {isHovering && (
              <div className="flex shrink-0 gap-1">
                <FileGalleryCardMenu
                  clip={file}
                  getItemMenuOptions={getItemMenuOptions}
                  getSelectionMenuOptions={getSelectionMenuOptions}
                  setIsMenuOpen={setIsMenuOpen}
                  trigger={
                    <CardAction
                      color="light"
                      data-testid={FILE_CARD_SUB_MENU_BUTTON}
                      icon={<EllipsisHorizontalIcon className="size-4" />}
                    />
                  }
                />
              </div>
            )}
          </div>
          <div className="mt-3 flex grow flex-col justify-end gap-1">
            <AssetGalleryCardMetaDetails
              clip={file}
              title={titleLabel}
              onTitleClick={onFileClick}
              onTitleCmdClick={onFileTitleCmdClick}
              /** Card width minus the left and right padding */
              width={cardWidth - 24}
              titleTestId={FILE_CARD_TITLE}
            />
            {shouldShowCustomFields && (
              <CustomFieldsDisplay className="px-1" customFields={customFields} onClick={onCustomFieldsClick} />
            )}
          </div>
        </div>
      )}
    </GalleryCard>
  );
};

export const FileGalleryCard = memo(_FileGalleryCard) as typeof _FileGalleryCard;
