import { AirActionTrackingLocation } from '@air/analytics';
import { type Asset, type Board } from '@air/api/types';
import { ConfirmationModal } from '@air/component-confirmation-modal';
import { Switch } from '@air/primitive-switch';
import { useToasts } from '@air/provider-toast';
import classNames from 'classnames';
import { noop } from 'lodash';
import pluralize from 'pluralize';
import { useState } from 'react';

import { useCreateDeleteBoardTask } from '~/components/DeleteBoardTasks/hooks/useCreateDeleteBoardTask';
import { DeleteAssetsWarning } from '~/components/Modals/DeleteBoards/DeleteAssetsWarning';
import { DELETE_ASSETS_AND_BOARDS_TOGGLE } from '~/constants/testIDs';
import { useShowSubscriptionExpiredModal } from '~/hooks/useShowSubscriptionExpiredModal';
import { useDeletePrivateClips } from '~/swr-hooks/clips/useDeletePrivateClips';

import { useIsUploadingToSelectedBoard } from '../DeleteBoards/util';

export interface DeleteAssetsAndBoardsModalProps {
  boardIds: Board['id'][];
  assetIds: Asset['id'][];
  onDelete?: () => void;
  parentBoardId?: string;
  trackLocation: AirActionTrackingLocation;
}

export const DeleteAssetsAndBoardsModal = ({
  assetIds,
  boardIds,
  onClose,
  onDelete = noop,
  parentBoardId,
  trackLocation,
}: AirModalProps<DeleteAssetsAndBoardsModalProps>) => {
  const { showToast } = useToasts();
  const [isDeleting, setDeleting] = useState(false);
  const [removeClips, setRemoveClips] = useState(false);
  const { showingSubscriptionExpiredModal } = useShowSubscriptionExpiredModal({ onClose });
  const { createDeleteBoardTask } = useCreateDeleteBoardTask();
  const { deletePrivateClips } = useDeletePrivateClips();
  const { isUploadingToBoard } = useIsUploadingToSelectedBoard(boardIds);

  if (showingSubscriptionExpiredModal) {
    return null;
  }

  const confirm = async () => {
    setDeleting(true);

    try {
      await Promise.all([
        createDeleteBoardTask({ boardIds, removeClips, trackLocation }),
        deletePrivateClips({ assetIds, trackLocation, parentBoardId }),
      ]);

      showToast(`Deleted ${pluralize('asset', assetIds.length, true)}`);
      onDelete();
      onClose();
    } catch (_error) {
      showToast(`There was an error. Please try again later.`);
      setDeleting(false);
    }
  };

  return (
    <ConfirmationModal
      ctaPrimaryText="Delete"
      id="delete-assets-and-boards"
      isDangerous
      isDisabled={isUploadingToBoard}
      isLoading={isDeleting}
      isOpen
      onConfirm={confirm}
      onDismiss={onClose}
      title="Delete selected boards and assets"
    >
      {isUploadingToBoard && (
        <div className="mb-4 font-medium text-red-9">
          Items are currently being uploaded to this board. The board cannot be deleted until this upload has completed.
        </div>
      )}

      <div className="mb-10 text-14 text-grey-10">
        You are about to delete <b>{pluralize('board', boardIds.length, true)}</b> and{' '}
        <b>{pluralize('asset', assetIds.length, true)}</b> and <b>all associated versions.</b>
        {removeClips
          ? ` This will delete the assets and their versions inside ${boardIds.length > 1 ? 'these' : 'this'}
        ${pluralize('board', boardIds.length)} from the workspace unless they appear in other boards.`
          : ` The assets inside the ${pluralize(
              'board',
              boardIds.length,
            )} you've selected will remain in your workspace.`}
      </div>

      <div className="mb-6 flex items-start">
        <div className={classNames('text-grey-12', boardIds.length === 1 ? 'mr-3' : 'mr-0')}>
          Delete assets inside {pluralize('this', boardIds.length)} {pluralize('board', boardIds.length)} from my
          workspace
        </div>
        <div className="flex items-center">
          <span className="mr-3 text-12 font-semibold uppercase text-grey-8">{removeClips ? 'ON' : 'OFF'}</span>
          <Switch
            checked={removeClips}
            data-testid={DELETE_ASSETS_AND_BOARDS_TOGGLE}
            id="remove-clips"
            onCheckedChange={(checked) => setRemoveClips(checked)}
          />
        </div>
      </div>

      {removeClips && <DeleteAssetsWarning />}
    </ConfirmationModal>
  );
};
