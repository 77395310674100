import { useTrackSwitchedSortType } from '@air/analytics';
import { SortDirection } from '@air/api/types';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useIsFlattenedView } from '~/hooks/search/useIsFlattenedView';
import { useIsSearchActive } from '~/hooks/search/useIsSearchActive';
import { centralizedBoardExistsSelector } from '~/store/centralizedBoard/selectors';
import { setSortingFieldAction } from '~/store/configViews/actions';
import {
  currentSortFieldDirectionSelector,
  currentSortFieldNameSelector,
  currentViewTypeNameSelector,
  savedConfigurableViewsSelector,
  sortingConfigViewOptionsSelector,
} from '~/store/configViews/selectors';
import { getDefaultSortDirection } from '~/store/configViews/utils';
import { useAirStore } from '~/utils/ReduxUtils';

import { ViewSortingMenuComponent, ViewSortingMenuComponentProps } from './ViewSortingMenuComponent';

export const ViewSortingMenu = memo(() => {
  const dispatch = useDispatch();
  const store = useAirStore();
  const isInBoardPage = useSelector(centralizedBoardExistsSelector);
  const { isSearchActive } = useIsSearchActive();
  const { isFlattenedView } = useIsFlattenedView();
  const { trackSwitchedSortType } = useTrackSwitchedSortType();

  const sortingConfigViewOptions = useSelector(sortingConfigViewOptionsSelector);
  const currentSortFieldName = useSelector(currentSortFieldNameSelector);
  const currentSortFieldDirection = useSelector(currentSortFieldDirectionSelector);

  const allowCustomSorting = isInBoardPage && !isSearchActive && !isFlattenedView;
  const currentlySortedBy = currentSortFieldName || '';

  const availableSortOptions = useMemo(() => {
    const options = sortingConfigViewOptions
      .filter((option) => {
        return option.fieldName !== 'customSort';
      })
      .map((option) => option.fieldName);

    if (allowCustomSorting) {
      options.push('customSort');
    }

    return options;
  }, [allowCustomSorting, sortingConfigViewOptions]);

  useEffect(() => {
    if (!allowCustomSorting && currentSortFieldName === 'customSort') {
      const savedConfigViews = savedConfigurableViewsSelector(store.getState());
      const currentViewType = currentViewTypeNameSelector(store.getState());

      // If custom sort is not available, try to switch to the sort field that is saved on this board
      // if cusrom sort is saved sort, set it to 'dateModified' desc (default for other views)
      const savedViewForViewType = savedConfigViews.find((view) => view.viewType.name === currentViewType);
      let defaultSortField = 'dateModified';
      let defaultSortDirection = SortDirection.desc;
      const savedSortField = savedViewForViewType?.sortFields[0];

      if (!!savedSortField && savedSortField.name !== 'customSort' && !!savedSortField.name) {
        defaultSortField = savedSortField.name;
        defaultSortDirection = savedSortField.direction;
      }

      dispatch(setSortingFieldAction({ sortingFieldName: defaultSortField, direction: defaultSortDirection }));
    }
  }, [allowCustomSorting, currentSortFieldName, dispatch, store]);

  const onSortChange: ViewSortingMenuComponentProps['onChange'] = useCallback(
    (sortingFieldName, direction) => {
      const newDirection =
        sortingFieldName === currentSortFieldName ? direction : getDefaultSortDirection(sortingFieldName);
      dispatch(setSortingFieldAction({ sortingFieldName, direction: newDirection }));
      trackSwitchedSortType({ sortingFieldName, direction: newDirection });
    },
    [currentSortFieldName, dispatch, trackSwitchedSortType],
  );

  if (!availableSortOptions.length) return null;

  return (
    <ViewSortingMenuComponent
      sortDirection={currentSortFieldDirection}
      availableSortOptions={availableSortOptions}
      currentlySortedBy={currentlySortedBy}
      onChange={onSortChange}
    />
  );
});

ViewSortingMenu.displayName = 'ViewSortingMenu';
