import { Fields } from '@air/next-icons';
import { HoverCard } from '@air/primitive-hover-card';
import { memo } from 'react';

import { CardAction } from '~/components/CardAction';
import { CustomFieldsDisplay } from '~/components/Gallery/CustomFieldsDisplay';
import { type AssignedCustomFieldValue } from '~/swr-hooks/customFields/types';

export type GalleryFardCustomFieldsActionProps = {
  customFields: AssignedCustomFieldValue[];
  onClick?: () => void;
};

export const GalleryCardCustomFieldsAction = memo(({ customFields, onClick }: GalleryFardCustomFieldsActionProps) => {
  return (
    <HoverCard
      openDelay={0}
      align="start"
      trigger={
        <CardAction data-testid="CUSTOM_FIELDS_INDICATOR" icon={<Fields className="size-4" />} onClick={onClick} />
      }
    >
      <CustomFieldsDisplay
        onClick={onClick}
        style={{ minWidth: 250, maxWidth: 400 }}
        customFields={customFields}
        shouldShowLabelInline
      />
    </HoverCard>
  );
});

GalleryCardCustomFieldsAction.displayName = 'GalleryCardCustomFieldsAction';
