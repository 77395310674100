import { useTrackDeselectedItems } from '@air/analytics';
import { PublicClip } from '@air/api';
import { Board, Clip, PublicBoard } from '@air/api/types';
import { resetSelectedItemsAction, selectedItemsSelector } from '@air/redux-selected-items';
import { Router } from 'next/router';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GalleryItemType, GetSelectionMenuOptionsType } from '~/components/Gallery/types';
import { SelectionActionBar } from '~/components/SelectionActionBar/index';
import { GetItemMenuOptionsCallback } from '~/constants/MenuOptions';
import {
  selectedBoardsSelector,
  selectedFileClipsSelector,
  selectedMediaClipsSelector,
} from '~/store/selectedItems/selectors';
import { getClipIdFromPath } from '~/utils/PathUtils';

export interface BoardsAndAssetsSelectionBarProps<B, C> {
  getAssetMenuOptions: GetItemMenuOptionsCallback<C>;
  getBoardMenuOptions: GetItemMenuOptionsCallback<B>;
  getFileMenuOptions: GetItemMenuOptionsCallback<C>;
  getSelectionMenuOptions: GetSelectionMenuOptionsType;
  quickActionsIds?: string[];
}

const _BoardsAndAssetsSelectionBar = <B extends Board | PublicBoard, C extends Clip | PublicClip>({
  getAssetMenuOptions,
  getBoardMenuOptions,
  getFileMenuOptions,
  getSelectionMenuOptions,
  quickActionsIds,
}: BoardsAndAssetsSelectionBarProps<B, C>) => {
  const dispatch = useDispatch();
  const { trackDeselectedItems } = useTrackDeselectedItems();

  const options = getSelectionMenuOptions('action-bar', true);
  const selectedItems = useSelector(selectedItemsSelector);
  const selectedBoardItems = useSelector(selectedBoardsSelector);
  const selectedMediaClipItems = useSelector(selectedMediaClipsSelector);
  const selectedFileItems = useSelector(selectedFileClipsSelector);
  const selectedItemsCount = selectedItems.length;

  useEffect(() => {
    const routeChangeStartHandler = (url: string) => {
      if (selectedItemsCount && !getClipIdFromPath(url)) {
        dispatch(resetSelectedItemsAction());
      }
    };

    Router.events.on('routeChangeStart', routeChangeStartHandler);

    return () => {
      Router.events.off('routeChangeStart', routeChangeStartHandler);
    };
  }, [dispatch, selectedItemsCount]);

  const onClearAll = useCallback(() => {
    trackDeselectedItems({
      fileSelectionCount: 0,
      assetSelectionCount: 0,
      boardSelectionCount: 0,
      totalSelectionCount: 0,
      trigger: 'action-bar',
      type: 'none',
    });
    dispatch(resetSelectedItemsAction());
  }, [dispatch, trackDeselectedItems]);

  const { menuOptions } = useMemo(() => {
    let menuOptions = options;
    if (selectedItems.length === 1) {
      if (selectedItems[0].type === GalleryItemType.board) {
        const board = selectedBoardItems[0];
        menuOptions = getBoardMenuOptions({ item: board as B })({ trackLocation: 'action-bar' });
      } else if (selectedItems[0].type === GalleryItemType.asset) {
        const clip = selectedMediaClipItems[0];
        menuOptions = getAssetMenuOptions({ item: clip as C })({ trackLocation: 'action-bar' });
      } else if (selectedItems[0].type === GalleryItemType.file) {
        const file = selectedFileItems[0];
        menuOptions = getFileMenuOptions({ item: file as C })({ trackLocation: 'action-bar' });
      }
    }

    return { menuOptions };
  }, [
    getAssetMenuOptions,
    getBoardMenuOptions,
    getFileMenuOptions,
    options,
    selectedBoardItems,
    selectedMediaClipItems,
    selectedFileItems,
    selectedItems,
  ]);

  return (
    <SelectionActionBar
      onClearAll={onClearAll}
      quickActionsIds={quickActionsIds}
      options={menuOptions}
      selectedItemsCount={selectedItems.length}
    />
  );
};

_BoardsAndAssetsSelectionBar.displayName = 'BoardsAndAssetsSelectionBar';

const _BoardsAndAssetsSelectionBarMemo = memo(_BoardsAndAssetsSelectionBar) as typeof _BoardsAndAssetsSelectionBar;

export const BoardsAndAssetsSelectionBar = _BoardsAndAssetsSelectionBarMemo as typeof _BoardsAndAssetsSelectionBarMemo;
