import { AirActionTrackingLocation, useTrackDeletedItems } from '@air/analytics';
import { Assets } from '@air/api';
import { type Asset } from '@air/api/types';
import { useToasts } from '@air/provider-toast';
import { useQueryClient } from '@tanstack/react-query';
import pluralize from 'pluralize';
import { useCallback } from 'react';
import { batch, useDispatch } from 'react-redux';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { deletedClipsSuccessAction } from '~/store/centralizedClip/actions';
import { getWorkspaceStorageKey } from '~/swr-hooks/subscriptions/useStorage';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { useUpdateBoardStats } from '~/utils/mutateUtils/BoardStats';
import { useRemoveAssetsFromAllViews } from '~/utils/mutateUtils/GalleryAssets';

export type DeletePrivateClipsParams = {
  assetIds: Asset['id'][];
  parentBoardId?: string;
  trackLocation: AirActionTrackingLocation;
};

export const useDeletePrivateClips = () => {
  const dispatch = useDispatch();
  const { currentWorkspace } = useCurrentWorkspace();
  const { removeAssetsFromAllViews } = useRemoveAssetsFromAllViews();
  const { updateBoardStats } = useUpdateBoardStats();
  const queryClient = useQueryClient();
  const { trackDeletedItems } = useTrackDeletedItems();
  const { showToast } = useToasts();

  const deletePrivateClips = useCallback(
    async ({ assetIds, parentBoardId, trackLocation }: DeletePrivateClipsParams) => {
      try {
        if (!currentWorkspace?.id) {
          throw new Error('No current workspace id');
        }

        const response = await Assets.batchDelete({
          assetIds,
          workspaceId: currentWorkspace.id,
        });

        if (response.deletedAssetIds.length > 0 || response.removedAssetIds.length > 0) {
          const mergedAssetIds = [...(response.deletedAssetIds || []), ...(response.removedAssetIds || [])];

          trackDeletedItems({
            item_ids: mergedAssetIds,
            item_total: mergedAssetIds.length,
            item_type: 'asset',
            location: trackLocation,
          });

          batch(() => {
            dispatch(deletedClipsSuccessAction({ assetIds: mergedAssetIds }));
          });

          if (!!currentWorkspace) {
            queryClient.invalidateQueries({ queryKey: getWorkspaceStorageKey(currentWorkspace?.id) });
          }

          removeAssetsFromAllViews({ assetIds: mergedAssetIds });

          if (parentBoardId) {
            updateBoardStats(parentBoardId);
          }
        }

        if (response.ignoredAssetIds.length > 0) {
          showToast(
            response.ignoredAssetIds.length === 1
              ? `Failed to delete asset`
              : `Failed to delete ${response.ignoredAssetIds.length} out of ${pluralize(
                  'asset',
                  assetIds.length,
                  true,
                )}`,
          );
        }

        return response;
      } catch (error) {
        throw reportErrorToBugsnag({
          error,
          context: 'Failed to delete clips',
          metadata: {
            Data: {
              assetIds,
              trackLocation,
            },
          },
        });
      }
    },
    [currentWorkspace, trackDeletedItems, removeAssetsFromAllViews, dispatch, queryClient, updateBoardStats, showToast],
  );

  return { deletePrivateClips };
};
